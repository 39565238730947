export const fabricShippingSkus: string[] = [
    "CEMESPMELRM",
    "CEMESPMELVR",
    "20005039",
    "QUI045",
    "1141022",
    "QUI1203",
    "QUI1008",
    "20005040",
    "QUI093",
    "833402",
    "QUI1386",
    "831413",
    "831405",
    "QUI1439",
    "834432",
    "834425",
    "QUI1433",
    "QUI1434",
    "832401",
    "QUI1605",
    "QUI1407",
    "QUI1440"
  ];