import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import { Button, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Box, Tooltip, Checkbox, FormControlLabel } from '@mui/material';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { fetchOrders, sendToLaudus } from '../../../../services/orderService';

import {
  generateOrderPDF,
  generateOrderXLS,
  updateOrder,
} from '../../../../services/orderService';
import DetailedOrderView from './DetailedOrderView';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { getPriceList } from '../../../../utils/dateManager';
import { fabricShippingSkus } from '../../../../constants/fabricShippingSkus';
import RowColorExplanationModal from './RowColorExplanationModal';

function CustomToolbar() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Tooltip title="Explicación de colores" arrow>
        <Button
          variant="outlined"
          sx={{ marginLeft: 2 }}
          onClick={handleOpenModal}
        >
          Colores
        </Button>
      </Tooltip>
      <RowColorExplanationModal open={openModal} onClose={handleCloseModal} />

    </GridToolbarContainer>
  );
}

interface Props {
  activeOrders: Order[];
}

const useOrdersState = () => {
  const { orders } = useAppSelector((state: any) => state.orderReducer);
  return { orders };
};

const useSellerName = () => {
  const { sellers } = useAppSelector((state: any) => state.sellerReducer);
  const getSellerName = (sellerId: string) => {
    const seller = sellers.find(
      (seller: { _id: string }) => seller._id === sellerId
    );
    return seller ? seller.name : '';
  };
  return getSellerName;
};

const useModalState = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  return { openModal, setOpenModal, selectedOrder, setSelectedOrder };
};

const BranchSelectorCell: React.FC<GridRenderCellParams> = (params) => {
  const [selectedBranch, setSelectedBranch] = useState('');
  const [loading, setLoading] = useState(false);

  const handleBranchChange = (event: SelectChangeEvent<string>) => {
    setSelectedBranch(event.target.value);
  };

  const handleDisableLaudus = (order: Order) => {
    if (order.sentToERP || order.blocked || !order.customer.laudusId || !order.customer.paymentFormId || order.discount > 0 || order.customer.name === "CLIENTE NUEVO") {
      return true
    }
    let blocked = false;
    order.products.forEach(product => {
      const code = product.code
      if (fabricShippingSkus.includes(code)
      ) {
        blocked = true;
      }
      if (product.discount > 0) {
        blocked = true;
      }
    })

    return blocked
  }

  const handleSendClick = async () => {
    setLoading(true);
    await sendToLaudus(params.row._id, selectedBranch)
    setLoading(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <FormControl variant="outlined" sx={{ minWidth: 150 }}>
        <InputLabel>Sucursal</InputLabel>
        <Select
          value={selectedBranch}
          onChange={handleBranchChange}
          label="Sucursal"
        >
          <MenuItem value="Arturo Prat">Arturo Prat</MenuItem>
          <MenuItem value="Santiago Arauco 960">Santiago Arauco 960</MenuItem>
          <MenuItem value="San Antonio">San Antonio</MenuItem>
          <MenuItem value="Zona Sur">Zona Sur</MenuItem>
        </Select>
      </FormControl>
      <Button
        sx={{ backgroundColor: 'green', color: 'white', marginLeft: 2 }}
        onClick={handleSendClick}
        disabled={
          handleDisableLaudus(params.row) || loading || !selectedBranch
        }
      >
        Enviar
      </Button>
    </Box>
  );
};
const useColumns = (getSellerName: (sellerId: string) => string) => {

  const columns: GridColDef[] = [
    {
      field: 'deleteButton',
      minWidth: 100,
      flex: 1,
      headerName: 'Eliminar',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: '#EC0013', color: 'white' }}
          onClick={async () => {
            await updateOrder({ deleted: !params.row.deleted }, params.row._id);
            await fetchOrders();
          }}
        >
          <DeleteForeverOutlined />
        </Button>
      ),
    },
    {
      field: 'id',
      minWidth: 200,
      flex: 1,
      headerName: 'Numero Referencia',

      valueGetter(params) {
        const date = new Date(params.row.date);
        return date.getTime();
      },
    },
    {
      field: 'date',
      minWidth: 100,
      flex: 1,
      headerName: 'Fecha',
      valueGetter(params) {
        const date = new Date(params.row.date);
        return `${date.getDate()}/${date.getMonth() + 1
          }/${date.getFullYear()} `;
      },
      sortComparator: (v1, v2) => {
        const date1 = new Date(
          `${v1.split('/')[1]}-${v1.split('/')[0]}-${v1.split('/')[2]}`
        );
        const date2 = new Date(
          `${v2.split('/')[1]}-${v2.split('/')[0]}-${v2.split('/')[2]}`
        );
        console.log(date1, date2);
        const date1Time = date1.getTime();
        const date2Time = date2.getTime();
        return date1Time - date2Time;
      },
    },
    {
      field: 'hour',
      minWidth: 100,
      flex: 1,
      headerName: 'Hora',
      valueGetter(params) {
        const date = new Date(params.row.date);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
      },
    },
    {
      field: 'customer',
      minWidth: 200,
      flex: 1,
      headerName: 'Cliente',
      valueGetter(params) {
        return params.row.customer.name;
      },
    },
    {
      field: 'seller',
      minWidth: 300,
      flex: 1,
      headerName: 'Vendedor',
      valueGetter(params) {
        return getSellerName(params.row.seller);
      },
    },
    {
      field: 'priceList',
      minWidth: 300,
      flex: 1,
      headerName: 'Lista de Precios',
      valueGetter(params) {
        return getPriceList(params.row.priceList);
      },
    },
    {
      field: 'sendToLaudus',
      minWidth: 300,
      flex: 1,
      headerName: 'Enviar a Laudus',
      renderCell: (params: GridRenderCellParams) => <BranchSelectorCell {...params} />,
    },
    {
      field: 'laudusId',
      minWidth: 150,
      flex: 1,
      headerName: 'Numero Laudus'
    },
    {
      field: 'total',
      minWidth: 100,
      flex: 1,
      headerName: 'Total Neto',
      valueGetter(params) {
        return params.row.discountedNetTotal.toLocaleString('es-CL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: 'totalProducts',
      minWidth: 100,
      flex: 1,
      headerName: 'Total Productos',
      valueGetter(params) {
        return params.row.products.length;
      },
    },
    {
      field: 'discount',
      minWidth: 100,
      flex: 1,
      headerName: 'Descuento',
      valueGetter(params) {
        if (params.row.discount > 0) {
          return 'Si';
        }
        let returnValue = 'No';
        params.row.products.forEach((product: { discount: number }) => {
          if (product.discount > 0) {
            returnValue = 'Si';
          }
        });
        return returnValue;
      },
      cellClassName: (params) => {
        if (params.row.discount > 0) {
          return 'si';
        }
        let returnValue = 'no';
        params.row.products.forEach((product: { discount: number }) => {
          if (product.discount > 0) {
            returnValue = 'si';
          }
        });
        return returnValue;
      },
    },
    {
      field: 'pdf',
      minWidth: 150,
      flex: 1,
      headerName: 'Descargar PDF',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: '#EC0013', color: 'white' }}
          onClick={() => {
            const date = new Date(params.row.date);
            const downloadName = `${date.getTime()}`;

            generateOrderPDF(params.row._id, downloadName);
          }}
        >
          PDF
        </Button>
      ),
    },
    {
      field: 'excel',
      minWidth: 150,
      flex: 1,
      headerName: 'Descargar Excel',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: '#1D6F42', color: 'white' }}
          onClick={() => {
            const date = new Date(params.row.date);
            const downloadName = `${date.getTime()}`;
            generateOrderXLS(params.row._id, downloadName);
          }}
        >
          EXCEL
        </Button>
      ),
    },
    {
      field: 'sentToERP',
      minWidth: 150,
      flex: 1,
      headerName: 'Enviado a ERP',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: 'blue', color: 'white' }}
          onClick={() => {
            if (!params.row.blocked) {
              updateOrder({ sentToERP: !params.row.sentToERP }, params.row._id);
            }
          }}
        >
          CARGADO
        </Button>
      ),
    },
    {
      field: 'blocked',
      minWidth: 150,
      flex: 1,
      headerName: 'Bloqueado',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: 'blue', color: 'white' }}
          onClick={() => {
            if (!params.row.sentToERP) {
              updateOrder({ blocked: !params.row.blocked }, params.row._id);
            }
          }}
        >
          BLOQUEADO
        </Button>
      ),
    },
    {
      field: 'fabricShipping',
      minWidth: 150,
      flex: 1,
      headerName: 'Despacho Fabrica',
      valueGetter(params) {
        return params.row.fabricShipping ? 'Si' : 'No';
      }
    },
    {
      field: 'customerPickup',
      minWidth: 150,
      flex: 1,
      headerName: 'Retira Cliente',
      valueGetter(params) {
        return params.row.customerPickup ? 'Si' : 'No';
      }
    },
    {
      field: 'pickupDate',
      minWidth: 150,
      flex: 1,
      headerName: 'Fecha Retiro',
      valueGetter(params) {
        if (params.row.pickupDate) {
          const date = new Date(params.row.pickupDate);
          return `${date.getDate()}/${date.getMonth() + 1
            }/${date.getFullYear()} `;
        }
        return '';
      },
    },
  ];
  return columns;
};

const useDataGrid = (columns: GridColDef[], rows: Order[]) => {
  const { openModal, setOpenModal, selectedOrder, setSelectedOrder } =
    useModalState();
  const [fabricShipping, setFabricShipping] = useState(false);
  const [customerPickup, setCustomerPickup] = useState(false);
  const [filteredRows, setFilteredRows] = useState(rows);

  useEffect(() => {
    let filtered = rows;
    if (fabricShipping) {
      filtered = filtered.filter((order) => order.fabricShipping);
    }
    if (customerPickup) {
      filtered = filtered.filter((order) => order.customerPickup);
    }
    setFilteredRows(filtered);
  }, [fabricShipping, customerPickup, rows]);

  return (
    <>
      <div className='flex p-2 gap-2'>
        <FormControlLabel
          control={
            <Checkbox
              checked={fabricShipping}
              onChange={(e) => setFabricShipping(e.target.checked)}
            />
          }
          label="Despachos desde Fabrica"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={customerPickup}
              onChange={(e) => setCustomerPickup(e.target.checked)}
            />
          }
          label="Retira Cliente"
        />
      </div>
      <DataGrid
        sx={{
          padding: 2,
          '& .sentToERP': {
            bgcolor: '#9ACD32',
          },
          '& .blocked': {
            bgcolor: '#FF0000',
          },
          '& .sinLaudusId': {
            bgcolor: '#87CEEB',
          },
          '& .fabricaNoProcesado': {
            bgcolor: '#dcbddf',
          },
          '& .fabricaProcesado': {
            bgcolor: '#ff8000',
          },
          '& .retiraClienteNoProcesado': {
            bgcolor: '#7433ff',
          },
          '& .retiraClienteProcesado': {
            bgcolor: '#c4ff33',
          },
          '& .si': {
            backgroundColor: '#FFFF00',
            color: '#000',
            fontWeight: '600',
          },
        }}
        getRowClassName={(params) => {
          if (params.row.customerPickup && !params.row.sentToERP) {
            return 'retiraClienteNoProcesado';
          }
          if (params.row.customerPickup && params.row.sentToERP) {
            return 'retiraClienteProcesado';
          }
          if (params.row.fabricShipping && !params.row.sentToERP) {
            return 'fabricaNoProcesado';
          }
          if (params.row.fabricShipping && params.row.sentToERP) {
            return 'fabricaProcesado';
          }
          if (!params.row.laudusId && params.row.sentToERP) {
            return 'sinLaudusId';
          }
          if (params.row.blocked) {
            return 'blocked';
          }
          if (params.row.sentToERP) {
            return 'sentToERP';
          }
          return '';
        }}
        autoHeight={true}
        columns={columns}
        rows={filteredRows}
        pageSize={20}
        getRowId={(row) => row._id}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: CustomToolbar }}
        onRowDoubleClick={(params) => {
          setSelectedOrder(params.row);
          setOpenModal(true);
        }}
      />
      <DetailedOrderView
        open={openModal}
        onClose={() => setOpenModal(false)}
        order={selectedOrder}
      ></DetailedOrderView>
    </>
  );
};

const ActiveOrdersViewManager: React.FC = () => {
  const { orders } = useOrdersState();
  const getSellerName = useSellerName();
  const columns = useColumns(getSellerName);

  return useDataGrid(
    columns,
    orders.filter((order: Order) => order.deleted === false)
  );
};

const ActiveOrdersViewTypist: React.FC<Props> = ({ activeOrders }) => {
  const getSellerName = useSellerName();
  const columns = useColumns(getSellerName);

  return useDataGrid(columns, activeOrders);
};

export { ActiveOrdersViewManager, ActiveOrdersViewTypist };
