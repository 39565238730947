import axios from "./client";
import { QuotationActions } from "../reducers/QuotationReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchQuotations = async () => {
  store.dispatch({ type: QuotationActions.RequestQuotations, payload: {} });
  const { data: quotations } = await axios.get<Quotation[]>(
    `${API_URL}quotations`
  );
  store.dispatch({
    type: QuotationActions.ReceiveQuotations,
    payload: quotations,
  });
};

export const generateQuotationPDF = (id: string, downloadName: string) => {
  axios
    .get(API_URL + `quotations/${id}/pdf`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${downloadName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};