import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Órdenes Sin Factura');

    // Configuración de las columnas
    worksheet.columns = [
        { header: 'Número Referencia', key: 'id', width: 20 },
        { header: 'Fecha', key: 'date', width: 15 },
        { header: 'Hora', key: 'hour', width: 10 },
        { header: 'Cliente', key: 'customer', width: 25 },
        { header: 'Comuna', key: 'commune', width: 15 },
        { header: 'Ruta de despacho', key: 'routeName', width: 15 },
        { header: 'Total Neto', key: 'total', width: 15 },
        { header: 'Total Productos', key: 'totalProducts', width: 15 },
        { header: 'enviado a Laudus', key: 'sentToLaudus', width: 15 },
        { header: 'Bloqueado', key: 'blocked', width: 15 },
        { header: 'Laudus ID', key: 'laudusId', width: 15 },
        { header: 'Despacho de Fabrica', key: 'fabricShipping', width: 15 },
        { header: 'Retira Cliente', key: 'customerPickup', width: 15 },
        { header: 'Fecha Retiro Cliente', key: 'customerPickupDate', width: 15 },
    ];

    // Agrega filas al Excel
    data.forEach((row) => {
        worksheet.addRow({
            id: new Date(row.date).getTime(),
            date: new Date(row.date).toLocaleDateString(),
            hour: new Date(row.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            customer: row.customer.name,
            commune: row.customer.commune,
            routeName: row.routeName || "Sin ruta",
            total: row.discountedNetTotal,
            totalProducts: row.products.length,
            sentToLaudus: row.sentToERP ? 'Sí' : 'No',
            blocked: row.blocked ? 'Sí' : 'No',
            laudusId: row.laudusId,
            fabricShipping: row.fabricShipping ? 'Sí' : 'No',
            customerPickup: row.customerPickup ? 'Sí' : 'No',
            customerPickupDate: row.customerPickupDate ? new Date(row.customerPickupDate).toLocaleDateString() : '',

        });
    });

    // Generar y guardar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Órdenes_Sin_Factura.xlsx');
};

export default handleGenerateExcel;
