import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import { Card } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";

import { fetchQuotations } from "../../../../services/quotationService";
import DetailedOrderView from "../../Common/Order/DetailedOrderView";
import Logo from "../../../Logo";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const QuotationsView: React.FC = () => {
  const { isLoading, quotations } = useAppSelector(
    (state: any) => state.quotationReducer
  );
  const [openModal, setOpenModal] = useState(false); //esto es para el modal
  const [selectedQuotation, setSelectedQuotation] = useState(null); //esto es para el modal
  const effectRan = useRef(false);
  useEffect(() => {
    if (effectRan.current === false) {
      fetchQuotations();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);
  const columns: GridColDef[] = [
    {
      field: "id",
      minWidth: 200,
      flex: 1,
      headerName: "Numero Referencia",

      valueGetter(params) {
        const date = new Date(params.row.date);
        return date.getTime();
      },
    },
    {
      field: "date",
      minWidth: 200,
      flex: 1,
      headerName: "Fecha",
      valueGetter(params) {
        const date = new Date(params.row.date);
        return `${date.getDate()}/${date.getMonth() + 1
          }/${date.getFullYear()} `;
      },
    },
    {
      field: "customer",
      minWidth: 200,
      flex: 1,
      headerName: "Cliente",
      valueGetter(params) {
        return params.row.customer.name;
      },
    },
    {
      field: "total",
      minWidth: 200,
      flex: 1,
      headerName: "Total",
      valueGetter(params) {
        return params.row.total.toLocaleString("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "totalProducts",
      minWidth: 200,
      flex: 1,
      headerName: "Total Productos",
      valueGetter(params) {
        return params.row.products.length;
      },
    },
    {
      field: "status",
      minWidth: 200,
      flex: 1,
      headerName: "Convertido en Pedido",
      valueGetter(params) {
        return params.row.convertedToOrder ? "Si" : "No";
      },
    },
  ];

  return (
    <div className="p-5 h-full overflow-auto">
      <Logo />
      <div className="text-xl mb-3">Cotizaciones</div>
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={quotations}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              onRowDoubleClick={(params) => {
                setSelectedQuotation(params.row);
                setOpenModal(true);
              }} //esto es para el modal
            />
            <DetailedOrderView
              open={openModal}
              onClose={() => setOpenModal(false)}
              order={selectedQuotation}
            ></DetailedOrderView>
          </Card>
        )}
      </div>
    </div>
  );
};

export default QuotationsView;
