import React from 'react';
import { Modal, Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

interface Props {
    open: boolean;
    onClose: () => void;
}

const RowColorExplanationModal: React.FC<Props> = ({ open, onClose }) => {
    const colorDescriptions = [
        { color: '#dcbddf', label: 'Fábrica No Procesado' },
        { color: '#ff8000', label: 'Fábrica Procesado' },
        { color: '#7433ff', label: 'Retira Cliente No Procesado' },
        { color: '#c4ff33', label: 'Retira Cliente Procesado' },
        { color: '#87CEEB', label: 'Sin Laudus ID' },
        { color: '#9ACD32', label: 'Enviado a ERP' },
        { color: '#FF0000', label: 'Bloqueado' },
        { color: '#FFFF00', label: 'Con Descuento' },

    ];

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2" mb={2}>
                    Colores de las Filas
                </Typography>
                <List>
                    {colorDescriptions.map((desc) => (
                        <ListItem key={desc.label} sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon>
                                <Box
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        bgcolor: desc.color,
                                        border: '1px solid black',
                                        marginRight: 2,
                                    }}
                                ></Box>
                            </ListItemIcon>
                            <ListItemText primary={desc.label} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Modal>
    );
};

export default RowColorExplanationModal;
