import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateExcelOrdersWithInvoices = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Órdenes');

    // Configuración de las columnas
    worksheet.columns = [
        { header: 'Número Referencia', key: '_id', width: 20 },
        { header: 'Numero Laudus', key: 'laudusId', width: 20 },
        { header: 'Fecha', key: 'date', width: 15 },
        { header: 'Cliente', key: 'customerName', width: 25 },
        { header: 'Vendedor', key: 'sellerName', width: 20 },
        { header: 'Total Neto con descuentos', key: 'discountedNetTotal', width: 20 },
        { header: 'Productos Pendientes (Cantidad)', key: 'totalPendingProducts', width: 20 },
        { header: 'Productos Pendientes (Monto $)', key: 'pendingProductsTotal', width: 25 },
        { header: 'Facturas', key: 'invoices', width: 40 },
        { header: 'Tiene Cotización', key: 'hasQuotation', width: 15 },
        { header: 'Monto Cotización', key: 'quotationAmount', width: 20 },
        { header: 'Convertido en Pedido Nuevamente', key: 'newStatus', width: 25 },
    ];

    // Agrega filas al Excel
    data.forEach((row) => {
        const pendingProductsTotal = row.pendingProducts.reduce(
            (acc, product) => acc + product.netPrice * product.quantity,
            0
        );

        worksheet.addRow({
            _id: row.dateInMilliseconds,
            laudusId: row.laudusId,
            date: new Date(row.date).toLocaleDateString(),
            customerName: row.customer.name,
            sellerName: row.seller.name,
            discountedNetTotal: row.discountedNetTotal,
            totalPendingProducts: row.pendingProducts.length,
            pendingProductsTotal: pendingProductsTotal,
            invoices: row.invoices.map((invoice) => invoice.invoiceLaudusId).join(', '),
            hasQuotation: row.quotation ? 'Sí' : 'No',
            quotationAmount: row.quotation ? row.quotation.discountedNetTotal : '',
            newStatus: row.quotation && row.quotation.convertedToOrder ? 'Sí' : 'No',
        });
    });

    // Generar y guardar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Órdenes.xlsx');
};

export default handleGenerateExcelOrdersWithInvoices;
