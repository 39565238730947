import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateExcelByProduct = async (orders, sellers) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Productos Pendientes');

    // Configuración de las columnas
    worksheet.columns = [
        { header: 'Número Referencia', key: '_id', width: 20 },
        { header: 'Numero Laudus', key: 'laudusId', width: 20 },
        { header: 'Fecha', key: 'date', width: 15 },
        { header: 'Cliente', key: 'customerName', width: 25 },
        { header: 'Vendedor', key: 'sellerName', width: 20 },
        { header: 'Producto (SKU)', key: 'sku', width: 20 },
        { header: 'Descripción Producto', key: 'productDescription', width: 40 },
        { header: 'Cantidad Pendiente', key: 'pendingQuantity', width: 20 },
        { header: 'Monto Pendiente ($)', key: 'pendingAmount', width: 20 },
        { header: 'Tiene Cotización', key: 'hasQuotation', width: 15 },
        { header: 'Cantidad Cotizada', key: 'quotedQuantity', width: 20 },
        { header: 'Convertido en Pedido Nuevamente', key: 'convertedToOrder', width: 25 },
    ];

    // Agrega filas al Excel
    orders.forEach((order) => {
        order.pendingProducts.forEach((pendingProduct) => {
            const { code, description, quantity, netPrice } = pendingProduct;

            // Verificar si el producto tiene cotización
            let hasQuotation = false;
            if(order.quotation && order.quotation.products)
             hasQuotation = order.quotation?.products.some(item => item.code === code);
    
            // Cantidad cotizada (si aplica)
            const quotedQuantity = hasQuotation
                ? order.quotation.products.find(item => item.code === code)?.quantity || 0
                : 0;

            // Convertido en pedido nuevamente
            const convertedToOrder = hasQuotation && order.quotation.convertedToOrder ? 'Sí' : 'No';

            // Agregar fila
            worksheet.addRow({
                _id: order.dateInMilliseconds,
                laudusId: order.laudusId,
                date: new Date(order.date).toLocaleDateString(),
                customerName: order.customer.name,
                sellerName: getSellerName(order.seller, sellers),
                sku: code,
                productDescription: description,
                pendingQuantity: quantity,
                pendingAmount: quantity * netPrice,
                hasQuotation: hasQuotation ? 'Sí' : 'No',
                quotedQuantity,
                convertedToOrder,
            });
        });
    });

    // Generar y guardar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Productos_Pendientes.xlsx');
};

const getSellerName = (sellerId, sellers) => {
    const seller = sellers.find(
        (seller) => seller._id === sellerId
    );
    return seller ? seller.name : '';
};


export default handleGenerateExcelByProduct;
